import request from '@/utils/oms-request'

export function addLogisticsProvider(data) {
  return request({
    url: 'oms/carrier/add',
    method: 'post',
    data
  })
}

export function updateLogisticsProvider(data) {
  return request({
    url: 'oms/carrier/update',
    method: 'put',
    data
  })
}
