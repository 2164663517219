import request from '@/utils/oms-request'

export function getLogisticsProviderList(params) {
  return request({
    url: `oms/carrier/page`,
    params
  })
}

export function deleteLogisticsProvider(id) {
  return request({
    url: `oms/carrier/${id}`,
    method: 'delete'
  })
}
