<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="isDetail"
    :close-on-press-escape="isDetail"
    :title="title"
    :visible.sync="visible"
    top="5vh"
  >
    <el-form
      ref="form"
      :disabled="isDetail"
      :model="form"
      :rules="rules"
      label-width="auto"
    >
      <el-form-item label="平台物流商" prop="carrier">
        <el-input v-model="form.carrier" clearable />
      </el-form-item>
      <el-form-item label="系统物流商" prop="carrierCode">
        <el-input v-model="form.carrierCode" clearable />
      </el-form-item>
    </el-form>
    <template v-if="!isDetail" #footer>
      <el-button @click="handleClose">取 消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addLogisticsProvider, updateLogisticsProvider } from './apis/addDialog'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      submitLoading: false,
      form: {
        carrier: '',
        carrierCode: ''
      },
      rules: {
        carrier: [
          {
            required: true,
            message: '必填',
            trigger: 'blur'
          }
        ],
        carrierCode: [
          {
            required: true,
            message: '必填',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    isDetail() {
      return this.type === 'detail'
    },
    isAdd() {
      return this.type === 'add'
    },
    isEdit() {
      return this.type === 'edit'
    },
    title() {
      return this.isDetail ? '查看' : this.isEdit ? '修改' : '添加'
    },
    submitFn() {
      return this.isAdd ? addLogisticsProvider : updateLogisticsProvider
    }
  },
  watch: {
    visible(val) {
      if (val && !this.isAdd) {
        this.form = {
          ...this.row
        }
      }
    }
  },
  methods: {
    handleClose() {
      if (this.submitLoading) {
        this.$message('正在执行中，请稍后关闭')
        return
      }
      this.form = {
        carrier: '',
        carrierCode: ''
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        this.submitLoading = true
        const { msg, code } = await this.submitFn(this.form).finally(() => {
          this.submitLoading = false
        })
        if (code === 0) {
          this.$message.success(msg)
          this.$emit('refresh')
          this.handleClose()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
