<template>
  <div>
    <vxe-toolbar>
      <template #buttons>
        <el-button :loading="queryLoading" @click="handleQuery">查询</el-button>
        <el-button type="primary" @click="handleOpenAddDialog()">添加</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column field="carrier" title="平台物流商" />
      <vxe-column field="carrierCode" title="系统物流商" />
      <vxe-column title="操作">
        <template v-slot="{row}">
          <el-button type="text" @click="handleOpenAddDialog('detail', row)">查看</el-button>
          <el-button type="text" @click="handleOpenAddDialog('edit', row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" @update="handlePagerUpdate" />
    <add-dialog
      :row="currentRow"
      :type="addDialogType"
      :visible.sync="addDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import AddDialog from './AddDialog.vue'
import { deleteLogisticsProvider, getLogisticsProviderList } from './apis/list'

export default {
  name: 'LogisticsProviderMappingList',
  components: {
    Paging,
    AddDialog
  },
  data() {
    return {
      addDialogType: '',
      currentRow: null,
      addDialogVisible: false,
      maxHeight: 0,
      queryLoading: false,
      tableData: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {

      }
    }
  },
  computed: {
    queryParams() {
      return {
        pageNo: this.pager.current,
        pageSize: this.pager.size
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
    this.handleQuery()
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    handleOpenAddDialog(type = 'add', row = null) {
      this.currentRow = row
      this.addDialogType = type
      this.addDialogVisible = true
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      if (this.queryChange) {
        this.pager.current = 1
      }
      const { datas } = await getLogisticsProviderList(this.queryParams, signal).finally(() => {
        this.queryLoading = this.queryChange = false
        this.controller = null
      })
      this.tableData = datas?.records || []
      this.pager.total = datas?.pager?.total || 0
    },
    handleDelete(row) {
      this.$confirm('确定删除吗', '提示', {
        closeOnClickModal: false,
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const { msg, code } = await deleteLogisticsProvider(row.id).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            if (code === 0) {
              this.$message.success(msg)
              done()
              await this.handleQuery()
            }
          } else {
            done()
          }
        }
      })
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
